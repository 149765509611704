<template>
  <landing :hero-image="heroImage">
    <mobile-full-page-wrapper :go-to-id="landingFieldsId" show-arrow>
      <header class="header">
        <business-logo class="business-logo" />
        <typography type="h3" class="page-title" light>
          Kia ora, let’s get you on the fuel programme designed for Kiwi
          business.
        </typography>
        <typography light>It'll take 5-10 minutes</typography>
        <typography inline light>You can save your application and come back to it later, just click on
          <tooltip :top="$vuetify.breakpoint.smAndUp" :bottom="$vuetify.breakpoint.xsOnly">
            <icon-button slot="activator">
              <a href="#">Save and share</a>
            </icon-button>
            <save-and-share-image v-if="$vuetify.breakpoint.mdAndUp" />
            <save-and-share-mobile-image v-if="$vuetify.breakpoint.smAndDown" />
          </tooltip><span v-if="$vuetify.breakpoint.mdAndUp"> at the top of the page.</span>
          <span v-if="$vuetify.breakpoint.smAndDown"> at the top of the side panel.</span>
        </typography>
      </header>
    </mobile-full-page-wrapper>

    <mobile-full-page-wrapper :id="landingFieldsId">
      <form class="form" @submit.prevent="onLandingSubmit">
        <div class="inputs">
          <typography light>Who will be the key contact for this Z Business account?</typography>
          <text-input
            :maxlength="125"
            v-model.trim.lazy="firstName"
            :rules="[() => $v.firstName.required || 'This field is required']"
            label="First name"
            name="firstName"
            @blur="$v.firstName.$touch()"
          />
          <text-input
            :maxlength="124"
            v-model.trim.lazy="lastName"
            :rules="[() => $v.lastName.required || 'This field is required']"
            label="Last name"
            name="lastName"
            @blur="$v.lastName.$touch()"
          />
          <text-input
            v-model.trim.lazy="email"
            :maxlength="320"
            :rules="[
              () => $v.email.required || 'This field is required',
              () => $v.email.email || 'Please provide a valid email address'
            ]"
            label="Email"
            name="ApplicantEmail"
            type="email"
            no-margin
            @blur="$v.email.$touch()"
          />

          <div class="market-promo-select d-flex pt-3">
            <Checkbox
              v-model="acceptedUpdates"
              name="ApplicantAcceptedUpdates"
            />

            <div>
              <typography type="subtitle-1">
                Yes! Let me know about fuel discounts, special business offers,
                and helpful information from Z Business.
              </typography>

              <typography type="subtitle-1">
                You can unsubscribe at any time.
              </typography>
            </div>
          </div>
        </div>

        <v-layout justify-center>
          <v-flex xs10 md12>
            <round-button
              :disabled="$v.$invalid"
              :loading="createApplicationInProgress"
              type="submit"
              name="GetStartedButton"
            >Get started</round-button>
          </v-flex>
        </v-layout>
        <br />
        <typography type="subtitle-1">Check out our
          <a
            rel="noopener noreferrer"
            :href="privacyPolicyLink"
            name="PrivacyPolicyLink"
            target="_blank"
            @click.stop
            >Privacy Policy
            </a> for how we use your information.
        </typography>
      </form>
    </mobile-full-page-wrapper>
  </landing>
</template>

<script>
import Landing from '@/components/templates/Landing.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import TextInput from '@/components/atoms/TextInput.vue';

import Checkbox from '@/components/atoms/Checkbox.vue';
import Typography from '@/components/atoms/Typography.vue';
import BusinessLogo from '@/assets/icons/z-business-logo.svg';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { REQUIREMENTS, PRIVACY_POLICY_LINK } from '@/constants/routes';
import { required } from 'vuelidate/lib/validators';
import { email } from '@/validators';
import { mapState } from 'vuex';
import { LANDING_FIELDS } from '@/constants/html-ids';
import { BRAND, PERSISTENT_HERO_IMAGE } from '@/constants/form';

import Tooltip from '@/components/atoms/Tooltip.vue';
import SaveAndShareImage from '@/assets/icons/save-and-share.svg';
import SaveAndShareMobileImage from '@/assets/icons/save-and-share-mobile.svg';

export default {
  components: {
    Checkbox,
    Landing,
    RoundButton,
    TextInput,
    Typography,
    BusinessLogo,
    MobileFullPageWrapper,
    Tooltip,
    SaveAndShareImage,
    SaveAndShareMobileImage
  },
  data() {
    return {
      landingFieldsId: LANDING_FIELDS,
      brand: BRAND,
      heroImage: PERSISTENT_HERO_IMAGE
    };
  },
  computed: {
    firstName: {
      get() {
        return this.$store.state.landing.firstName;
      },
      set(value) {
        this.$store.dispatch('landing/setFirstName', value);
      }
    },
    lastName: {
      get() {
        return this.$store.state.landing.lastName;
      },
      set(value) {
        this.$store.dispatch('landing/setLastName', value);
      }
    },
    email: {
      get() {
        return this.$store.state.landing.email;
      },
      set(value) {
        this.$v.email.$touch();
        this.$store.dispatch('landing/setEmail', value);
      }
    },
    acceptedUpdates: {
      get() {
        return this.$store.state.landing.acceptedUpdates;
      },
      set(value) {
        this.$store.dispatch('landing/setAcceptedUpdates', value);
      }
    },
    ...mapState({
      saveId: state => state.saver.applicationId,
      createApplicationInProgress: state =>
        state.progress.createApplication.inProgress
    }),
    privacyPolicyLink: () => PRIVACY_POLICY_LINK,
  },
  methods: {
    async onLandingSubmit() {
      if (!this.saveId) {
        // Generate new id, then save
        await this.$store.dispatch('saver/createApplication');
      }

      // Redirect to next page
      this.$router.push(REQUIREMENTS);
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      required,
      email
    }
  }
};
</script>

<style lang="scss">
.market-promo-select {
  .accent--text {
    color: grey !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.header {
  @include sm {
    margin: rem(20px 0 70px);
  }
}

.business-logo {
  @include xs-only {
    position: absolute;
    top: rem(40px);
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include sm {
    margin-bottom: rem(65px);
  }
}

.page-title {
  max-width: 100%;
  width: rem(260px);
  margin: rem(0 auto 10px auto);

  @include sm {
    margin: rem(0 0 10px 0);
    width: auto;
  }
}

.form {
  @include sm {
    padding-bottom: rem(80px);
  }
}

.inputs {
  margin: rem(85px 0 65px);

  .name-text-field {
    margin-bottom: rem(50px);
  }
}
</style>
